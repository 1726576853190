@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .help :is(h1, h2, h3, h4, h5) {
        @apply text-pink-600;
    }

    .help :is(img) {
        @apply border-solid border-2 border-pink-600 shadow-lg;
    }

    h1, h2, h3, h4, h5 {
        @apply font-medium;
    }

    h1 {
        @apply text-4xl mb-6 mt-12;
    }

    h2 {
        @apply text-3xl mb-5 mt-10;
    }

    h3 {
        @apply text-2xl mb-4 mt-8;
    }

    h4 {
        @apply text-xl mb-3 mt-6;
    }

    h5 {
        @apply text-lg mb-2 mt-4;
    }

    p {
        @apply text-base mb-2;
    }

    ol, ul {
        @apply my-4;
    }

    ul ul, ul ol, ol ol, ol ul {
        @apply my-0 ml-6;
    }

    figure {
        @apply mt-12 mb-12;
    }

    figcaption {
        @apply text-sm mt-1 text-center;
    }

    img {
        @apply mx-auto drop-shadow-md;
    }
}

@layer components {
    .btn {
        @apply font-bold py-2 px-4 rounded text-white hover:no-underline hover:text-white cursor-pointer inline-block;
    }

    .btn-edit {
        @apply bg-green-700 hover:bg-green-800 disabled:bg-gray-500 disabled:cursor-default;
    }

    .btn-primary {
        @apply bg-blue-700 hover:bg-blue-800;
    }

    .btn-publish {
        @apply bg-yellow-700 hover:bg-yellow-900;
    }

    .btn-delete {
        @apply bg-red-600 hover:bg-red-800;
    }

    .btn-info {
        @apply bg-gray-500 hover:bg-gray-700;
    }

    .flash-success {
        @apply bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 mb-4 shadow-md;
    }

    .flash-success .flash-icon {
        @apply fill-current h-6 w-6 text-green-500 mr-4;
    }

    .flash-error {
        @apply bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 mb-4 shadow-md;
    }

    .flash-error .flash-icon {
        @apply fill-current h-6 w-6 text-red-500 mr-4;
    }

    .form-label {
        @apply mb-2 block text-sm font-medium text-gray-700;
    }

    .form-input {
        @apply shadow appearance-none border rounded w-full py-2 px-3 mr-4;
    }

    .pair-item {
        @apply p-2 mb-2 bg-white shadow rounded-lg;
    }

    .answer, .option {
        @apply flex justify-between items-center;
    }

    .question {
        @apply bg-gray-100 mb-10;
    }

    .answered {
        @apply mb-2;
    }

    .question-text {
        @apply bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 mb-4 shadow-md;
    }

    .question-text .icon {
        @apply fill-current h-6 w-6 text-green-500 mr-4;
    }

    .question-feedback-text {
        @apply bg-gray-100 border-t-4 border-gray-500 rounded-b text-gray-900 px-4 py-3 mb-4 shadow-md;
    }

    .question-feedback-text .icon {
        @apply fill-current h-6 w-6 text-gray-500 mr-4;
    }

    .subject-area-link {
        @apply transition duration-500 ease-in-out rounded bg-white hover:bg-gray-100 transform hover:-translate-y-1 hover:scale-105 w-full hover:no-underline lg:max-w-full lg:flex shadow;
    }
}


@layer utilities {
    .active-menu {
        @apply bg-gray-700 bg-opacity-25;
    }
}

a {
    @apply text-blue-600 no-underline;
}

a:hover {
    @apply text-blue-600 underline;
}

#page ul {
    @apply list-disc;
    @apply ml-6;
}

#page ol {
    @apply list-decimal;
    @apply ml-6;
}

#page p {
    @apply my-2;
}

#page table {
    @apply border-collapse;
    @apply table-auto;
    @apply m-2;
}

#page tr, #page th, #page td {
    @apply border;
    @apply border-black;
    @apply p-2;
}

#page img {
  display: inline-block;
}

.sr-only {
    @apply h-px;
    @apply w-px;
    @apply overflow-hidden;
    @apply absolute;
}

.main-menu-item {
    @apply flex items-center mt-4 py-2 px-6 hover:bg-gray-700 hover:bg-opacity-25 text-gray-400 hover:text-white hover:no-underline
}

.main-menu-item-no-link {
    @apply flex items-center mt-4 py-2 px-6 text-gray-400
}

.main-menu-sub-item {
    & .cal-count {
        @apply px-2 py-1 mr-2 text-xs font-bold leading-none text-gray-300 bg-gray-600 rounded-full
    }

    @apply text-sm flex items-center justify-between mt-2 py-2 px-16 text-gray-400 hover:no-underline hover:text-white hover:bg-gray-700 hover:bg-opacity-25
}

.main-menu-sub-item-no-link {
    @apply text-sm flex items-center mt-2 py-2 px-16 text-gray-400
}

.main-menu-active {
    @apply bg-gray-700 bg-opacity-25 text-white hover:bg-gray-700 hover:text-white hover:bg-opacity-25 hover:no-underline
}

.skip-to-content-link {
    @apply bg-black text-white px-5 py-2 -left-96 absolute transition duration-500
}

.skip-to-content-link:focus {
    @apply left-0
}

.pathways-form {
    @apply border border-gray-200 rounded p-4 w-full
}

.pathways-input {
    @apply appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500
}

.pathways-select {
    @apply appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 mr-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500
}

.pathways-multi-select {
    @apply border border-gray-200 p-2 w-full mb-2
}

.pathways-btn {
    @apply bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
}

.pathways-label {
    @apply block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2
}

.page-link-item {
    @apply p-2 bg-gray-100 shadow rounded-lg mb-2
}

.responsive-iframe {
    @apply aspect-w-5 aspect-h-5 w-11/12 pb-2
}

.responsive-iframe > * {
    @apply relative min-h-screen min-w-max
}

.footer-menu-item {
    @apply text-gray-600 hover:text-gray-600
}

.footer-menu-item-selected {
    @apply text-black hover:text-gray-800 hover:no-underline
}

.footer-li {
    @apply inline-block
}

.li-break {
    @apply border-r mr-2 pr-2 border-gray-400
}
